<template>
  <div>
    <v-btn
      class="mb-2"
      depressed
      x-small
      @click="$emit('close')"
    >
      Close x
    </v-btn>

    <div v-if="bikes[0].booking.driver_notes.length > 0" class="d-flex flex-column">
      <div v-for="note in notes" :key="note.id" class="mt-2 mb-4">
        <NoteBox :data="note">
          {{ note.content }}
        </NoteBox>
      </div>
    </div>
    <ManifestMapBike
      v-for="bike in bikes"
      :key="bike.id"
      v-bind="{ bike, manifest }"
      @bike:added="bike => $emit('bike:added', bike)"
      @bike:removed="bike => $emit('bike:removed', bike)"
    />
  </div>
</template>
<script type="text/javascript">
import ManifestMapBike from '@/components/manifests/ManifestMapBike'
import NoteBox from '@/components/notes/NoteBox'

export default {
  name: 'ManifestMapPopup',

  components: {
    ManifestMapBike,
    NoteBox
  },

  props: {
    bikes: {
      type: Array,
      required: true
    },

    manifest: {
      type: Object,
      required: true
    }
  },

  computed: {
    notes () {
      return this.bikes[0].booking.driver_notes || []
    }
  }
}
</script>
