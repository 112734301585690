<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="700px"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="headline">Edit Manifest</span>

          <v-spacer></v-spacer>

          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-if="manifest.published_at" @click="updateManifest(true)">
                <v-list-item-title>Un-Publish Manifest</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteManifest" color="error" :disabled="loading">
                <v-list-item-title>Delete Manifest</v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="form.description"
                  label="Short Description"
                  outlined
                  dense
                  autofocus
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-dialog
                  ref="onDateSelector"
                  v-model="onDateModal"
                  :return-value.sync="$moment(form.on_date).format('YYYY-MM-DD')"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="$moment(form.on_date).format('ddd Do MMM')"
                      outlined
                      dense
                      label="Date"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="form.on_date"
                    :min="$moment().add(1, 'day').format('YYYY-MM-DD')"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="onDateModal = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.onDateSelector.save(form.on_date)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="form.estimated_started_at"
                  label="Estimated Start Time"
                  :items="availableManifestStartTimes"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                  autofocus
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-if="showStartedAt"
                  v-model="form.started_at"
                  label="Actual Start Time"
                  :items="availableManifestStartTimes"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                  autofocus
                />
                <div v-else-if="manifest.started_at" class="grey lighten-2 rounded pl-2 pb-1 pr-2">
                  <p class="mb-0 pb-0">Started At</p>
                  <p class="font-weight-bold mb-0 pb-0 text-caption">
                    {{ $moment(manifest.started_at).format('h:mma') }}
                    <v-btn x-small depressed text outlined color="primary" @click="showStartedAt = true">
                      Change
                    </v-btn>
                  </p>
                </div>
                <v-btn v-if="showStartedAt && manifest.started_at" x-small depressed text outlined color="primary" @click="showStartedAt = false">
                  Reset
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ApiAutoCompleteField
                  v-model="form.vehicle"
                  :current="form.vehicle"
                  label="Vehicle"
                  path="vehicles"
                >
                  <template v-slot="{ item: vehicle }">
                    <v-list-item-avatar v-if="vehicle.img_url">
                      <v-avatar size="30" color="primary">
                        <img
                          :src="vehicle.img_url"
                          :alt="vehicle.name">
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title><strong>{{ vehicle.name }}</strong></v-list-item-title>
                      <v-list-item-subtitle v-text="vehicle.vin" />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>Capacity <strong>{{ vehicle.max_bikes }}</strong></v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </ApiAutoCompleteField>
              </v-col>
              <v-col>
                <ApiAutoCompleteField
                  v-model="form.driver"
                  :current="form.driver"
                  label="Driver"
                  path="admin-users"
                >
                  <template v-slot="{ item: driver }">
                    <v-list-item-avatar>
                      <v-avatar size="30" color="primary">
                        <img
                          v-if="driver.profile_img_url"
                          :src="driver.profile_img_url"
                          :alt="driver.name">
                        <strong v-else class="white--text" style="font-size: 10px;">{{ driver.initials }}</strong>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title><strong>{{ driver.name }}</strong></v-list-item-title>
                      <v-list-item-subtitle v-text="driver.email" />
                    </v-list-item-content>
                  </template>
                </ApiAutoCompleteField>
              </v-col>
            </v-row>
          </v-form>

          <FormErrors ref="formErrors" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="updateManifest(false)"
          >
            Update Manifest <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Manifest created successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the manifest.
    </SnackBar>

  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import HasManifestStartTimes from '@/mixins/HasManifestStartTimes'
import ApiAutoCompleteField from '@/components/ApiAutoCompleteField'
import FormErrors from '@/components/FormErrors'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'ManifestEditDialog',

  mixins: [ HasDialog, HasForm, HasManifestStartTimes ],

  components: {
    ApiAutoCompleteField,
    FormErrors,
    SnackBar
  },

  props: {
    manifest: {
      type: Object,
      required: true
    }
  },

  watch: {
    dialog: {
      immediate: true,
      handler () {
        this.form = {
          ...this.manifest,
          ...{
            on_date: this.$moment(this.manifest.on_date).format('YYYY-MM-DD'),
            estimated_started_at: this.$moment(this.manifest.estimated_started_at, true).format('HH:mm:ss'),
            started_at: this.manifest.started_at ? this.$moment(this.manifest.started_at, true).format('HH:mm:ss') : null
          }
        }
        if (this.$refs.vehicleAutoComplete && this.$refs.driverAutoComplete) {
          this.$refs.vehicleAutoComplete.getData()
          this.$refs.driverAutoComplete.getData()
        }
      }
    },
  },

  data () {
    return {
      showStartedAt: false,
      loading: false,
      onDateModal: false
    }
  },

  methods: {
    deleteManifest () {
      this.loading = true
      this.$api.delete({
        path: `manifests/${this.manifest.id}`
      })
        .then(() => {
          this.$router.push({ name: 'manifests' })
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
          this.error = error
        })
        .finally(() => this.loading = false)
    },

    updateManifest (unpublish = false) {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$api.persist('put', {
          path: `manifests/${this.manifest.id}`,
          object: {
            ...this.form,
            ...{
              vehicle_id: this.form.vehicle.id,
              driver_id: this.form.driver.id,
              started_at: this.manifest.started_at ? this.$momentDateTime(this.manifest.started_at, true) : null,
              reset_started_at: this.showStartedAt ? this.form.started_at : null,
              published_at: unpublish ? null : (this.manifest.published_at ? this.$momentDateTime(this.manifest.published_at, true) : null),
              published_by: unpublish ? null : (this.manifest.published_by ? this.manifest.published_by.id : null)
            }
          }
        })
          .then(() => {
            this.$refs.successBar.open()
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
            this.error = error
          })
          .finally(() => this.loading = false)
      }
    }
  }
}
</script>
