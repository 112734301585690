<template>
  <div>
    <v-card
      class="mx-auto rounded-lg pb-1 mb-2"
      outlined
    >
      <v-list-item>
        <v-list-item-content>
          <div class="overline mb-4">
            <router-link :to="{ name: 'bookings.show', params: { bookingId: bike.booking.id } }">
              {{ bike.ref }}              
            </router-link>
          </div>
          <v-list-item-title class="headline mb-1">
              <v-icon>mdi-bicycle</v-icon>
            {{ bike.manafacturer ? `${bike.manafacturer} -` : bike.manafacturer }} {{ bike.colour }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-caption">
            <v-icon x-small>mdi-bicycle</v-icon>
            {{ bike.type_label }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="text-caption mt-2">
            <v-icon x-small>mdi-map-marker</v-icon>
            {{ type === 'TYPE_COLLECTION' ? bike.booking.collection_address : bike.booking.return_address }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="mb-4 text-caption">
            <v-icon x-small>mdi-map-marker</v-icon>
            <strong>{{ type === 'TYPE_COLLECTION' ? bike.booking.collection_postcode : bike.booking.return_postcode }}</strong>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-icon x-small>mdi-account</v-icon>
            {{ bike.booking.customer_name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-icon color="green" x-small>mdi-truck-fast</v-icon>
            {{ bike.collection_status_label }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-icon x-small>mdi-wrench-outline</v-icon>
            {{ bike.workshop_status_label }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-icon color="red" x-small>mdi-truck-fast</v-icon>
            {{ bike.return_status_label }}
          </v-list-item-subtitle>
          <v-divider class="my-2" />
          <v-list-item-subtitle>
            <v-icon x-small>mdi-wrench-outline</v-icon>
            {{ bike.job_type_label }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="bike.booking.po_number">
            <v-icon x-small>mdi-wrench-outline</v-icon>
            {{ bike.booking.po_number }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          v-if="!existsOnManifest"
          :loading="loading"
          class="mt-2"
          block
          color="primary"
          small
          @click="addBikeToManifest">Add
        </v-btn>
        <v-btn
          v-else
          :loading="loading"
          class="mt-2"
          block
          color="error"
          small
          @click="removeBikeFromManifest">Remove
        </v-btn>
      </v-card-actions>
    </v-card>

    <FormErrors ref="formErrors" />
    <SnackBar success ref="successBar">
      Bike added successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue adding the bike.
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'ManifestMapBike',

  components: {
    FormErrors,
    SnackBar
  },

  props: {
    bike: {
      type: Object,
      required: true
    },

    manifest: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      error: {},
      loading: false
    }
  },

  computed: {
    type () {
      if ((this.$momentDate(this.manifest.on_date) === this.$momentDate(this.bike.booking.collection_date)) || (this.$momentDate(this.manifest.on_date) === this.$momentDate(this.bike.rearranged_collection_date))) {
        return 'TYPE_COLLECTION'
      }
      return 'TYPE_RETURN'
    },

    existsOnManifest () {
      var exists = false
      this.manifest.bikes.forEach(bike => {
        if (bike.id === this.bike.id) {
          exists = true
        }
      })
      return exists
    }
  },

  methods: {
    addBikeToManifest () {
      this.loading = true
      this.$api.persist('post', {
        path: 'bike-manifests',
        object: {
          booking_bike_id: this.bike.id,
          manifest_id: this.manifest.id,
          type: this.type
        }
      })
        .then(({ data: bikeManifest }) => {
          if (bikeManifest.type === 'TYPE_RETURN') {
            this.$set(this.bike, 'return_manifest', { booking_bike_manifest: bikeManifest })
          }
          if (bikeManifest.type === 'TYPE_COLLECTION') {
            this.$set(this.bike, 'collection_manifest', { booking_bike_manifest: bikeManifest })
          }
          this.$refs.successBar.open()
          this.$emit('bike:added', this.bike)
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
          this.error = error
        })
        .finally(() => this.loading = false)
    },

    removeBikeFromManifest () {
      this.loading = true
      var manifest = (this.type === 'TYPE_COLLECTION' ? this.bike.collection_manifest : this.bike.return_manifest)
      var id = manifest.booking_bike_manifest ? manifest.booking_bike_manifest.id : null
      if (id) {
        this.$api.delete({
          path: `bike-manifests/${id}`
        })
          .then(() => {
            if (this.type === 'TYPE_RETURN') {
              this.$set(this.bike, 'return_manifest', null)
            }
            if (this.type === 'TYPE_COLLECTION') {
              this.$set(this.bike, 'collection_manifest', null)
            }
            this.$refs.successBar.open()
            this.$emit('bike:removed', this.bike)
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
            this.error = error
          })
          .finally(() => this.loading = false)
      }
    }
  }
}
</script>
