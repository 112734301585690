<template>
  <div class="manifest-map__wrapper">
    <div
      class="manifest-map__actions"
      :style="`background: ${this.$vuetify.theme.dark ? '#121212' : '#FFFFFF'};`"
    >
      <div class="filter__jobs-not-on-manifest">
        <v-btn
          :outlined="!hideOtherJobs"
          color="primary"
          depressed
          style="height: 40px"
          @click="toggleJobsNotOnManifest"
        >
          {{ hideOtherJobs ? 'Show Jobs Not On Manifest' : 'Hide Jobs Not On Manifest' }}
        </v-btn>
      </div>
      <div class="filter__jobs-on-manifest">
        <v-btn
          :outlined="!hideManifestJobs"
          color="primary"
          depressed
          style="height: 40px"
          @click="toggleJobsOnManifest"
        >
          {{ hideManifestJobs ? 'Show Jobs On Manifest' : 'Hide Jobs On Manifest' }}
        </v-btn>
      </div>
      <div class="filter__time-windows-requested">
        <v-select
          v-model="filters.timeWindows"
          :items="timeWindows.filter(timeWindow => ['MORNING', 'AFTERNOON'].includes(timeWindow.value))"
          label="Filter Requested Time Windows"
          item-text="label"
          item-value="value"
          dense
          multiple
          clearable
          outlined
          @change="filterJobsByTimeWindow"
        />
      </div>
    </div>
    <div class="manifest-map__container">
      <div
        :class="{
          'map_inner_dialog': true,
          'hide': !showDialog
        }"
        :style="`background: ${this.$vuetify.theme.dark ? '#121212' : '#FFFFFF'};`"
      >
        <ManifestMapPopup
          v-if="dialogBikes.length > 0"
          v-bind="{ bikes: this.dialogBikes, manifest }"
          @close="showDialog = false"
        />
      </div>
      <div ref="map" id="map" style="height: 100vh;" />
    </div>
  </div>
</template>
<script type="text/javascript">
import mapboxgl from 'mapbox-gl'
import map from 'lodash.map'
import filter from 'lodash.filter'
import find from 'lodash.find'
import ManifestMapPopup from '@/components/manifests/ManifestMapPopup'
import HasManifestId from '@/mixins/HasManifestId'

export default {
  name: 'ManifestMap',

  mixins: [ HasManifestId ],

  components: {
    ManifestMapPopup
  },

  props: {
    timeWindows: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },

  data () {
    return {
      hideOtherJobs: false,
      hideManifestJobs: false,
      showDialog: false,
      map: null,
      manifest: {},
      attachedBikesToday: [],
      bikes: [],
      dialogBikes: [],
      visibleMarkers: [],
      filters: {
        timeWindows: []
      }
    }
  },

  mounted () {
    mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyaXN0aWFuYnJheWJyb29rZWhhdmViaWtlIiwiYSI6ImNrYWU1N2k2ZDFzZXUzNHM5aDdpbzQzN3YifQ.5Q9qXakKRTbgj5Zcbm27Zg'
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/christianbraybrookehavebike/ckae5bdty10pj1ipnz3d7ikvx',
      center: [this.$hubs.currentHub.longitude, this.$hubs.currentHub.latitude],
      zoom: 9.5
    })

    this.map.on('load', () => {
      this.getManifest()
      this.addWorkshop()
      this.addBaseRoute()

      if (window.Echo) {
        window.Echo.private(`${this.manifestId}.routes`)
          .listen('RouteCalculated', () => {
            this.getManifest(false)
          })
      }
    })
  },

  methods: {
    getManifest (loadJobs = true) {
      this.$api.get({
        path: `manifests/${this.manifestId}`,
        params: {
          with: ['bikes', 'route']
        }
      })
        .then(({ data: manifest }) => {
          this.manifest = manifest

          if (manifest.route) {
            var routeGeoJSON = window.turf.featureCollection([
              window.turf.feature(manifest.route.geometry)
            ])
            this.map.getSource('route').setData(routeGeoJSON)
          }

          this.getAllAttachedBikes()
          if (loadJobs) {
            this.getJobs()
          }
        })
    },

    // Add some base route style information
    addBaseRoute () {
      // Add an empty source call 'route'
      this.map.addSource('route', {
        type: 'geojson',
        data: window.turf.featureCollection([])
      })

      // Style the route line
      this.map.addLayer({
        id: 'routeline-active',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#02a09b',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12, 3,
            22, 12
          ]
        }
      }, 'waterway-label')

      // Style the route arrows
      this.map.addLayer({
        id: 'routearrows',
        type: 'symbol',
        source: 'route',
        layout: {
          'symbol-placement': 'line',
          'text-field': '▶',
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12, 24,
            22, 60
          ],
          'symbol-spacing': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12, 30,
            22, 160
          ],
          'text-keep-upright': false
        },
        paint: {
          'text-color': '#3887be',
          'text-halo-color': 'hsl(55, 11%, 96%)',
          'text-halo-width': 3
        }
      }, 'waterway-label')
    },

    addWorkshop () {
      var jrs = window.turf.featureCollection([window.turf.point([this.$hubs.currentHub.longitude, this.$hubs.currentHub.latitude])])

      this.map.addLayer({
        id: 'workshop',
        type: 'circle',
        source: {
          data: jrs,
          type: 'geojson'
        },
        paint: {
          'circle-radius': 10,
          'circle-color': 'white',
          'circle-stroke-color': '#00a09b',
          'circle-stroke-width': 2
        }
      })

      this.map.addLayer({
        id: 'workshop-symbol',
        type: 'symbol',
        source: {
          data: jrs,
          type: 'geojson'
        },
        layout: {
          'icon-image': 'bicycle-11',
          'icon-size': 1
        },
        paint: {
          'text-color': '#00a09b'
        }
      })
    },

    getAllAttachedBikes () {
      this.$api.get({
        path: 'bike-manifests',
        params: {
          cr_date: this.$moment(this.manifest.on_date).format('YYYY-MM-DD'),
          limit: 1000 * 100
        }
      })
        .then(({ data: bikes }) => {
          this.attachedBikesToday = bikes
        })
    },

    getJobs (populateMap = true) {
      this.bikes = []
      this.$api.get({
        path: 'jobs',
        params: {
          with: ['booking.driverNotes', 'manifests'],
          limit: 100 * 1000,
          exclude_collection_status: [
            'STATUS_CANCELLED'
          ],
          exclude_workshop_status: ['STATUS_AWAITING_PARTS', 'STATUS_TRIAGE_STARTED', 'STATUS_TRIAGE_UNABLE_TO_CONTACT', 'STATUS_TRIAGE_CONFIRMED', 'STATUS_WORK_UNDERWAY', 'STATUS_TRIAGE_COMPLETED', 'STATUS_WASH_STARTED_NO_TRIAGE', 'STATUS_QC_FAILED', 'STATUS_PARTS_ARRIVED', 'STATUS_TRIAGE_CONFIRMATION_STARTED', 'STATUS_WASH_COMPLETED_NO_TRIAGE', 'STATUS_WASH_COMPLETED_TRIAGE_COMPLETE', 'STATUS_WASH_STARTED_TRIAGE_COMPLETE', 'STATUS_AWAITING_TRIAGE', 'STATUS_CUSTOMER_CONSIDERING'],
          cr_date: this.$moment(this.manifest.on_date).format('YYYY-MM-DD')
        }
      })
        .then(({ data: bikes }) => {
          this.bikes = bikes
          if (populateMap) {
            this.$nextTick(() => {
              this.populateMap()
            })
          }
        })
    },

    populateMap () {
      this.bikes.forEach(bike => {
        // Collections
        if (bike.booking.collection_latitude && bike.booking.collection_longitude && (this.manifest.on_date === (bike.rearranged_collection_date || bike.booking.collection_date))) {
          this.addJobToMap({ lng: bike.booking.collection_longitude, lat: bike.booking.collection_latitude, bike }, true)
        }

        // Returns
        if (bike.booking.return_latitude && bike.booking.return_longitude && (this.manifest.on_date === (bike.rearranged_return_date || bike.booking.return_date))) {
          this.addJobToMap({ lng: bike.booking.return_longitude, lat: bike.booking.return_latitude, bike }, false)
        }
      })
    },

    addJobToMap (job, collection) {
      if (job.bike.job_type !== 'JOB_TYPE_DOORSTEP' || (collection && (job.bike.job_type === 'JOB_TYPE_DOORSTEP'))) {
        var bookingBikes = []
        this.bikes.forEach(bike => {
          if (bike.booking_id === job.bike.booking_id) {
            bookingBikes.push(bike)
          }
        })

        var selected = false
        this.manifest.bikes.forEach(bike => {
          if (bike.id === job.bike.id) {
            selected = true
          }
        })
        this.attachedBikesToday.forEach(bike => {
          if (bike.booking_bike_id === job.bike.id) {
            selected = true
          }
        })

        let jobStats = {
          ref: job.bike.ref ? job.bike.ref.toString() : '-',
          qty: bookingBikes.length,
        }

        var feature = window.turf.featureCollection([])

        // If we have a return and a collection at the same location on different bookings, seperate them
        if (this.checkForMore(job.bike.booking_id, job.lng, job.lat)) {
          this.checkForMore(null, job.lng, job.lat).forEach((otherJob, key) => {
            if (otherJob.booking_id === job.bike.booking_id) {
              job.lng = job.lng - (0.001 * key)
            }
          })
        }

        var pt = window.turf.point(
          [job.lng, job.lat],
          {
            orderTime: Date.now(),
            key: job.bike.id
          }
        )
        feature.features.push(pt)

        if (['JOB_TYPE_FLEET', 'JOB_TYPE_FLEET_COMPLAINT', 'JOB_TYPE_DOORSTEP', 'JOB_TYPE_DOORSTEP_COMPLAINT', 'JOB_TYPE_HUB_AND_SPOKE', 'JOB_TYPE_HUB_AND_SPOKE_COMPLAINT'].includes(job.bike.job_type)) {
          this.map.addLayer({
            id: `jobs-outer-circle-${job.bike.id}`,
            metadata: {
              bike_id: job.bike.id
            },
            type: 'circle',
            source: {
              data: feature,
              type: 'geojson'
            },
            paint: {
              'circle-radius': 20,
              'circle-color': selected ? (collection ? '#c1ccc2' : '#dea8a8') : 'white',
              'circle-stroke-color': ['JOB_TYPE_FLEET', 'JOB_TYPE_FLEET_COMPLAINT'].includes(job.bike.job_type) ? 'blue' : ['JOB_TYPE_HUB_AND_SPOKE', 'JOB_TYPE_HUB_AND_SPOKE_COMPLAINT'].includes(job.bike.job_type) ? '#ff9718' : 'yellow',
              'circle-stroke-width': 2
            }
          })
        }

        this.map.addLayer({
          id: `jobs-circle-${job.bike.id}`,
          metadata: {
            bike_id: job.bike.id
          },
          type: 'circle',
          source: {
            data: feature,
            type: 'geojson'
          },
          paint: {
            'circle-radius': 18,
            'circle-color': selected ? (collection ? '#c1ccc2' : '#dea8a8') : 'white',
            'circle-stroke-color': collection ? '#4caf50' : (['STATUS_AWAITING_QC', 'STATUS_QC_STARTED'].includes(job.bike.workshop_status) ? 'black' : '#ff5252'),
            'circle-stroke-width': 2
          }
        })
        this.map.addLayer({
          id: `jobs-symbol-${job.bike.id}`,
          type: 'symbol',
          source: {
            data: feature,
            type: 'geojson'
          },
          layout: {
            'text-field': `${jobStats.ref}${jobStats.qty > 1 ? `\nx${jobStats.qty}` : ''}`,
            'text-size': 11,
            'text-line-height': 1
          },
          paint: {
            'text-color': collection ? 'green' : 'red'
          }
        })

        if (this.visibleMarkers.length < 1 || typeof find(this.visibleMarkers, { id: job.bike.id }) === 'undefined') {
          this.visibleMarkers.push({
            id: job.bike.id,
            bike: job.bike,
            visible: true
          })
        }

        this.map.on('mouseenter', `jobs-circle-${job.bike.id}`, () => {
          this.map.getCanvas().style.cursor = 'pointer'
        })
        this.map.on('mouseleave', `jobs-circle-${job.bike.id}`, () => {
          this.map.getCanvas().style.cursor = ''
        })
        this.map.on('click', `jobs-circle-${job.bike.id}`, (e) => {
          e.preventDefault()
          this.dialogBikes = bookingBikes
          this.showDialog = true
        })
        this.map.on('click', (e) => {
          if (e.defaultPrevented === false) {
            this.dialogBikes = {}
            this.showDialog = false
          }
        })
      }
    },

    checkForMore(bookingId, lng, lat) {
      return filter(this.bikes, (bike) => {
        if (bike.booking.id !== bookingId) {
          return (bike.booking.collection_longitude === lng && bike.booking.collection_latitude === lat) || (bike.booking.return_longitude === lng && bike.booking.return_latitude === lat)          
        }
      })
    },

    markJobSelected (id, isCollection) {
      var mapLayer = this.map.getLayer(`jobs-circle-${id}`)

      if(typeof mapLayer !== 'undefined') {
        this.map.setPaintProperty(`jobs-circle-${id}`, 'circle-color', isCollection ? '#c1ccc2' : '#dea8a8')
      }
    },

    markJobUnSelected (id) {
      var mapLayer = this.map.getLayer(`jobs-circle-${id}`)

      if(typeof mapLayer !== 'undefined') {
        this.map.setPaintProperty(`jobs-circle-${id}`, 'circle-color', 'white')
      }
    },

    highlightJob (id) {
      var mapLayer = this.map.getLayer(`jobs-circle-${id}`)

      if(typeof mapLayer !== 'undefined') {
        this.map.setPaintProperty(`jobs-circle-${id}`, 'circle-radius', 25)
      }
    },

    unHighlightJob (id) {
      var mapLayer = this.map.getLayer(`jobs-circle-${id}`)

      if(typeof mapLayer !== 'undefined') {
        this.map.setPaintProperty(`jobs-circle-${id}`, 'circle-radius', 17)
      }
    },

    toggleJobsNotOnManifest () {
      this.hideOtherJobs = !this.hideOtherJobs
      this.updateMarkers()
    },

    toggleJobsOnManifest () {
      this.hideManifestJobs = !this.hideManifestJobs
      this.updateMarkers()
    },

    filterJobsByTimeWindow () {
      this.updateMarkers()
    },

    updateMarkers () {
      this.visibleMarkers = map(this.visibleMarkers, (marker => {
        const mapLayer = this.map.getLayer(`jobs-circle-${marker.id}`)
        const mapLayerSymbol = this.map.getLayer(`jobs-symbol-${marker.id}`)
        const mapLayerOuterCircle = this.map.getLayer(`jobs-outer-circle-${marker.id}`)

        const isOnManifest = typeof find(this.manifest.bikes, { id: marker.id }) !== 'undefined'
        const isIncludedInTimeWindowFilter = this.filters.timeWindows.length < 1 || (this.filters.timeWindows.length > 0 && (this.filters.timeWindows.includes(marker.bike.booking.collection_time_window) || this.filters.timeWindows.includes(marker.bike.booking.return_time_window)))

        const isVisible = (!(this.hideManifestJobs && isOnManifest) && !(this.hideOtherJobs && !isOnManifest) && isIncludedInTimeWindowFilter)

        if (typeof mapLayer !== 'undefined') {
          this.map.setLayoutProperty(`jobs-circle-${marker.id}`, 'visibility', isVisible ? 'visible' : 'none')
        }
        if (typeof mapLayerSymbol !== 'undefined') {
          this.map.setLayoutProperty(`jobs-symbol-${marker.id}`, 'visibility', isVisible ? 'visible' : 'none')
        }
        if (typeof mapLayerOuterCircle !== 'undefined') {
          this.map.setLayoutProperty(`jobs-outer-circle-${marker.id}`, 'visibility', isVisible ? 'visible' : 'none')
        }
        
        return {
          ...marker,
          visible: isVisible
        }
      }))
    }
  }
}
</script>
<style type="text/css">
  .manifest-map__wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  .manifest-map__container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .manifest-map__actions {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 0.5rem;
    width: 100%;
    box-shadow: #4040402e 0px 5px 11px 0px;
    padding: 5px 0;
    z-index: 1;
  }
  .manifest-map__actions > div > button {
    width: 100%;
    height: 100%;
  }
  .manifest-map__actions .v-input.v-select .v-text-field__details {
    display: none;
  }
  .map_inner_dialog {
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 100%;
    box-shadow: #4040402e 5px 0px 11px 0px;
    transition: left 0.2s ease-out;
    overflow: scroll;
    padding: 0 10px 0 0;
    z-index: 2;
  }
  .map_inner_dialog.hide {
    left: -420px;
  }
  @media screen and (max-width: 959px) {
    .manifest-map__container {
      position: relative;
      height: 800px;
    }
    .manifest-map__actions {
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.75rem 0.5rem;
    }
    .manifest-map__actions .filter__time-windows-requested {
      grid-column: auto / span 2;
    }
  }
  @media screen and (max-width: 599px) {
    .manifest-map__container {
      height: 60vh;
    }
    .manifest-map__actions {
      grid-template-columns: 1fr;
    }
    .manifest-map__actions .filter__time-windows-requested {
      grid-column: auto / span 1;
    }
    .map_inner_dialog {
      width: calc(100vw - 20px);
    }
    .map_inner_dialog.hide {
      left: -100vw;
    }
  }
</style>