<template>
  <div>
    <p class="mt-0 mb-0">
      <strong>Send {{ collection ? 'Collection' : 'Return' }} Notifications: </strong>
      <span v-if="manifest[sentAtProp]">{{ $momentDateTimeHuman(manifest[sentAtProp]) }} - {{ manifest[sentCountProp] }} Sent</span>
      <v-btn v-else x-small color="primary" text :loading="loading" @click="sendNotifications">Send</v-btn>
    </p>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'ManifestSendNotificationsButton',

  props: {
    manifest: {
      type: Object,
      required: true
    },

    collection: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    sentAtProp () {
      if (this.collection) {
        return 'collection_notifications_sent_at'
      }
      return 'return_notifications_sent_at'
    },

    sentCountProp () {
      if (this.collection) {
        return 'collection_notifications_sent'
      }
      return 'return_notifications_sent'
    },

    notsentCountProp () {
      if (this.collection) {
        return 'collection_notifications_not_sent'
      }
      return 'return_notifications_not_sent'
    }
  },

  methods: {
    sendNotifications () {
      var path = this.collection ? 'collection' : 'return'
      this.loading = true
      this.$api.persist('post', {
        path: `manifests/${this.manifest.id}/send-notifications/${path}`
      })
        .then(() => {
          //
        })
        .catch(() => {
          //
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
