<template>
  <v-container fluid>

    <InnerPageBackHeader @back="$router.go(-1)">
      <template v-slot:right>
        <div class="d-flex justify-content-between align-items-center w-full">
          <div class="pt-2">
            <p class="mb-0 pb-0" style="line-height: 0.1;"><small><strong>Created At:</strong> {{ $momentDate(manifest.created_at) }}</small></p>
            <p class="mb-0 mt-0 pt-0"><small><strong>Updated At:</strong> {{ $momentDateTimeHuman(manifest.updated_at) }}</small></p>
          </div>
          <v-btn
            outlined
            dark
            small
            depressed
            color="primary"
            @click="refreshManifest"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </template>
    </InnerPageBackHeader>

    <v-row class="mt-2">
      <v-col
        cols="12"
        md="5"
        class="manifest-items-column"
      >
        <v-card
          :loading="loading"
          outlined
        >
          <v-card-text>
            <div class="d-flex justify-around">
              <div class="d-block">
                <h2 class="text-left w-full">Manifest For: {{ $momentDate(manifest.on_date) }}</h2>
              </div>
              <div class="d-block">
                <v-btn
                  v-user-can:UPDATE_MANIFESTS
                  v-if="manifest.id"
                  small
                  text
                  color="primary"
                  @click="editDialog = true"
                >
                  Edit
                </v-btn>
              </div>
            </div>

            <p v-if="manifest.description" class="mt-4">{{ manifest.description }}</p>

            <v-alert
              v-if="manifest.route"
              border="left"
              color="info"
              outlined
              text
            > 
              <v-list-item>
                <v-list-item-content three-line>
                  <v-list-item-title class="blue--text font-weight-medium">Driving</v-list-item-title>
                  <v-list-item-subtitle v-if="manifest.route.legs" class="blue--text">{{ manifest.route.duration }} <small>({{ (manifest.route.legs.length - 2) * 15 }} minutes of wait time)</small></v-list-item-subtitle>
                  <v-list-item-subtitle class="blue--text">{{ manifest.route.distance }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="manifest.route.legs">
                <v-list-item-content two-line>
                  <v-list-item-title class="blue--text font-weight-medium">Stops</v-list-item-title>
                  <v-list-item-subtitle v-if="manifest.route.legs" class="blue--text">{{ manifest.route.legs.length - 2 }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content three-line>
                  <v-list-item-title class="blue--text font-weight-medium">Start Time</v-list-item-title>
                  <v-list-item-subtitle class="blue--text">Planned: {{ $moment(manifest.estimated_started_at).format('h:mma') }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="blue--text">Actual: {{ manifest.started_at ? $moment(manifest.started_at).format('h:mma') : '-' }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-alert>

            <v-divider />

            <v-list-item>
              <v-list-item-content three-line>
                <v-list-item-title class="font-weight-medium">Vehicle</v-list-item-title>
                <v-list-item-subtitle v-text="manifest.vehicle.name" />
                <v-list-item-subtitle v-text="manifest.vehicle.vin" />
                <v-list-item-subtitle>{{ manifest.vehicle.max_bikes }} bike capacity</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content two-line>
                <v-list-item-title class="font-weight-medium">Driver</v-list-item-title>
                <v-list-item-subtitle v-text="manifest.driver.name" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="manifest.published_at">
              <v-list-item-content two-line>
                <v-list-item-title class="font-weight-medium">Published</v-list-item-title>
                <v-list-item-subtitle v-text="`${$momentDateTimeHuman(manifest.published_at)} (${(manifest.published_by ? manifest.published_by.name : null)})`" />
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <template v-if="manifest.published_at">
              <ManifestSendNotificationsButton v-bind="{ manifest }" collection class="mt-4" />
              <ManifestSendNotificationsButton v-bind="{ manifest }" />
            </template>

            <v-btn
              class="mt-3"
              block
              @click="calculateRoute"
              :loading="loading"
            >
              Re-Calculate Route
            </v-btn>
            <v-btn
              v-if="!manifest.published_at"
              class="mt-3"
              color="success"
              block
              @click="publishManifest"
              :loading="loading"
            >
              Publish Manifest
            </v-btn>
            <v-btn
              v-if="manifest.published_at"
              class="mt-3"
              block
              :to="{ name: 'manifests.driver-view', params: { manifestId: manifest.id } }"
            >
              Driver View
            </v-btn>
            <v-btn
              v-if="manifest.published_at"
              :href="manifest.pdf_url"
              class="mt-3"
              color="success"
              block
              :loading="loading"
            >
              Print Manifest
            </v-btn>

            <v-divider
              v-user-can:REORDER_MANIFESTS
              v-if="manifest.published_at"
              class="my-4"
            />

            <v-btn
              v-user-can:REORDER_MANIFESTS
              v-if="manifest.published_at"
              class="mt-3 white--text"
              block
              small
              color="red"
              :loading="loading"
              @click="reverseRunOrder"
            >
              Reverse Run Order
            </v-btn>
          </v-card-text>
        </v-card>

        <ManifestBikesTable
          ref="manifestBikesTable"
          :manifest="{ ...manifest, ...{ id: manifestId }}"
          :time-windows="timeWindows"
          @mouseover="bike => $refs.manifestMap.highlightJob(bike.id)"
          @mouseleave="bike => $refs.manifestMap.unHighlightJob(bike.id)"
        />
      </v-col>
      <v-col
        cols="12"
        md="7"
        class="manifest-map-column"
      >
        <ManifestMap
          ref="manifestMap"
          :manifest-id="manifestId"
          :time-windows="timeWindows"
        />
      </v-col>
    </v-row>

    <ManifestEditDialog
      :dialog.sync="editDialog"
      v-bind="{ manifest }"
    />

    <SnackBar
      ref="errorBar"
      error
    >
      {{ error.message }}
    </SnackBar>

  </v-container>
</template>
<script type="text/javascript">
import InnerPageBackHeader from '@/components/InnerPageBackHeader'
import ManifestMap from '@/components/manifests/ManifestMap'
import HasManifestId from '@/mixins/HasManifestId'
import ManifestEditDialog from '@/components/manifests/ManifestEditDialog'
import ManifestSendNotificationsButton from '@/components/manifests/ManifestSendNotificationsButton'
import ManifestBikesTable from '@/components/manifests/ManifestBikesTable'
import debounce from 'lodash.debounce'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'ShowManifest',

  components: {
    InnerPageBackHeader,
    ManifestMap,
    ManifestEditDialog,
    ManifestSendNotificationsButton,
    ManifestBikesTable,
    SnackBar
  },

  mixins: [ HasManifestId ],

  data () {
    return {
      editDialog: false,
      loading: false,
      error: {},
      timeWindows: [],
      attachedBikesToday: [],
      manifest: {
        route: {},
        driver: {},
        vehicle: {},
        bikes: []
      }
    }
  },

  mounted () {
    this.getManifest()
    this.getTimeWindows()

    if (window.Echo) {
      window.Echo.private(`manifests.${this.manifestId}`)
        .listen('ManifestUpdated', () => {
          debounce(() => {
            if (this.$refs.manifestBikesTable) {
              this.$refs.manifestBikesTable.getBikes()
            }
            this.getManifest()
          }, 1000)
        })
      window.Echo.private(`${this.manifestId}.routes`)
        .listen('RouteCalculated', () => {
          if (this.$refs.manifestBikesTable) {
            this.$refs.manifestBikesTable.getBikes()
          }
          this.getManifest()
        })
      window.Echo.private(`${this.manifestId}.bikes`)
        .listen('BookingBikeManifestCreated', () => {
          if (this.$refs.manifestBikesTable) {
            this.$refs.manifestBikesTable.getBikes()
          }
          this.getManifest()
        })
        .listen('BookingBikeManifestDeleted', () => {
          if (this.$refs.manifestBikesTable) {
            this.$refs.manifestBikesTable.getBikes()
          }
          this.getManifest()
        })
      window.Echo.private('booking-bike-manifests')
        .listen('BookingBikeManifestCreated', (e) => {
          if (this.$refs.manifestMap) {
            this.$refs.manifestMap.markJobSelected(e.booking_bike_id, e.type === 'TYPE_COLLECTION')
          }
        })
        .listen('BookingBikeManifestDeleted', (e) => {
          if (this.$refs.manifestMap) {
            this.$refs.manifestMap.markJobUnSelected(e.booking_bike_id, e.type === 'TYPE_COLLECTION')
          }
        })
    }
  },

  methods: {
    getManifest () {
      this.loading = true
      this.$api.get({
        path: `manifests/${this.manifestId}`,
        params: {
          with: ['driver', 'vehicle', 'publishedBy', 'route']
        }
      })
        .then(({ data: manifest }) => {
          this.manifest = manifest
        })
        .catch(error => {
          this.error = error
        })
        .finally(() => this.loading = false)
    },

    getTimeWindows () {
      this.$api.get({
        path: 'manifest-time-windows'
      })
        .then(timeWindows => {
          var windows = []
          Object.keys(timeWindows).forEach(key => {
            windows.push({
              label: timeWindows[key],
              value: key
            })
          })
          this.timeWindows = windows
        })
    },

    refreshManifest () {
      this.getManifest();
      if (this.$refs.manifestMap) {
        this.$refs.manifestMap.getManifest(false);
      }
      if (this.$refs.manifestBikesTable) {
        this.$refs.manifestBikesTable.getBikes()
      }
    },

    publishManifest () {
      this.loading = true
      this.$api.persist('post', {
        path: `manifests/${this.manifestId}/publish`,
      })
        .then(({ data: manifest }) => {
          this.manifest.published_at = manifest.published_at
        })
        .catch(error => {
          this.error = error
        })
        .finally(() => this.loading = false)
    },

    calculateRoute () {
      this.loading = true

      this.$api.persist('post', {
        path: `manifests/${this.manifestId}/calculate-route`,
      })
        .catch(error => {
          this.error = error
          this.$refs.errorBar.open()
        })
        .finally(() => { this.loading = false })
    },

    reverseRunOrder () {
      this.loading = true

      this.$api.persist('post', {
        path: `manifests/${this.manifestId}/reverse-order`,
      })
        .catch(error => {
          this.error = error
          this.$refs.errorBar.open()
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style type="text/css">
  .td_input .v-text-field__details {
      display: none;
  }
  .sortable-ghost.collection {
    background: #81C784!important;
  }
  .return.sortable-ghost {
    background: #E57373!important;
  }
  .collection  {
    background: #e8f5e9;
  }
  .return  {
    background: #ffebee;
  }
  @media screen and (min-width: 960px) {
    .manifest-map-column,
    .manifest-items-column {
      height: 100vh;
      max-height: calc(100vh - 172px);
    }
    .manifest-items-column {
      overflow-y: auto;
    }
  }
</style>
