<template>
  <div>
    <v-btn v-if="(job.booking.driver_notes ? job.booking.driver_notes : []).length > 0" depressed outlined x-small color="orange" @click.stop="dialog = true">
      <v-icon x-small>mdi-exclamation</v-icon>
      {{ job.booking.driver_notes.length }} Note{{ job.booking.driver_notes.length > 1 ? 's' : null }}
    </v-btn>

    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Driver Notes For {{ job.ref }}</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <div v-for="note in job.booking.driver_notes" :key="note.id" class="mb-4 mt-2">
            <NoteBox :data="note">
              {{ note.content }}
              <template #info>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="grey"
                      small
                      dense
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ $momentDateTimeHuman(note.created_at) }} by {{ note.created_by.name }}
                    <v-avatar size="20" color="primary">
                      <img
                        :src="note.created_by.profile_img_url"
                        :alt="note.created_by.name">
                    </v-avatar>
                  </span>
                </v-tooltip>
              </template>
            </NoteBox>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click.stop="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import NoteBox from '@/components/notes/NoteBox'

export default {
  name: 'ManifestJobNotesButton',

  components: {
    NoteBox
  },

  props: {
    job: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      dialog: false
    }
  }
}
</script>
