export default {
  computed: {
    availableManifestStartTimes () {
      return [
        {
          label: this.$moment().hours(8).minutes(0).seconds(0).format('h:mma'),
          value: this.$moment().hours(8).minutes(0).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(8).minutes(30).seconds(0).format('h:mma'),
          value: this.$moment().hours(8).minutes(30).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(9).minutes(0).seconds(0).format('h:mma'),
          value: this.$moment().hours(9).minutes(0).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(9).minutes(30).seconds(0).format('h:mma'),
          value: this.$moment().hours(9).minutes(30).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(10).minutes(0).seconds(0).format('h:mma'),
          value: this.$moment().hours(10).minutes(0).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(10).minutes(30).seconds(0).format('h:mma'),
          value: this.$moment().hours(10).minutes(30).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(11).minutes(0).seconds(0).format('h:mma'),
          value: this.$moment().hours(11).minutes(0).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(11).minutes(30).seconds(0).format('h:mma'),
          value: this.$moment().hours(11).minutes(30).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(12).minutes(0).seconds(0).format('h:mma'),
          value: this.$moment().hours(12).minutes(0).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(12).minutes(30).seconds(0).format('h:mma'),
          value: this.$moment().hours(12).minutes(30).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(13).minutes(0).seconds(0).format('h:mma'),
          value: this.$moment().hours(13).minutes(0).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(13).minutes(30).seconds(0).format('h:mma'),
          value: this.$moment().hours(13).minutes(30).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(14).minutes(0).seconds(0).format('h:mma'),
          value: this.$moment().hours(14).minutes(0).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(14).minutes(30).seconds(0).format('h:mma'),
          value: this.$moment().hours(14).minutes(30).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(15).minutes(0).seconds(0).format('h:mma'),
          value: this.$moment().hours(15).minutes(0).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(15).minutes(30).seconds(0).format('h:mma'),
          value: this.$moment().hours(15).minutes(30).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(16).minutes(0).seconds(0).format('h:mma'),
          value: this.$moment().hours(16).minutes(0).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(16).minutes(30).seconds(0).format('h:mma'),
          value: this.$moment().hours(16).minutes(30).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(17).minutes(0).seconds(0).format('h:mma'),
          value: this.$moment().hours(17).minutes(0).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(17).minutes(30).seconds(0).format('h:mma'),
          value: this.$moment().hours(17).minutes(30).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        },
        {
          label: this.$moment().hours(18).minutes(0).seconds(0).format('h:mma'),
          value: this.$moment().hours(18).minutes(0).seconds(0).add(-(this.$moment().utcOffset()), 'm').format('HH:mm:ss')
        }
      ]
    }
  }
}