<template>
  <div>
    <v-card
      :loading="loading"
      class="mt-2"
      outlined
    >
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead class="text-left">
              <tr style="white-space: nowrap;">
                <th>Run</th>
                <th>Job</th>
                <th>CR</th>
                <th>Requested Window</th>
                <th>Assigned Window</th>
                <th>C&R Status</th>
                <th>Workshop Status</th>
                <th>Type</th>
                <th>Address</th>
                <th>Email Sent</th>
              </tr>
            </thead>
            <tbody class="text-left" v-sortable="{ onEnd: reorder, handle: '.reorder' }">
              <tr
                v-for="bike in bikes"
                :key="bike.id"
                :data-bike="bike.booking_bike_manifest.id"
                :class="{ 'return': bike.booking_bike_manifest.type === 'TYPE_RETURN', 'collection': bike.booking_bike_manifest.type === 'TYPE_COLLECTION' }"
                @mouseover="$emit('mouseover', bike)"
                @mouseleave="$emit('mouseleave', bike)"
              >
                <td class="p-0" style="min-width: 100px">
                  <v-icon
                    v-if="manifest.published_at"
                    small
                    class="reorder"
                    style="cursor: move;"
                  >
                    mdi-cursor-move
                  </v-icon>
                  <strong class="ml-1">{{ bike.booking_bike_manifest.order }}</strong>
                  <small
                    :class="{
                      'ml-1': true,
                      'red--text': bike._running_van_count > manifest.vehicle.max_bikes
                    }"
                  >
                    ({{ bike._running_van_count }})
                  </small>
                </td>
                <td class="p-0" style="min-width: 100px">
                  <router-link :to="{ name: 'bookings.candr', params: { bookingId: bike.booking.id }, query: { job_number: bike.ref } }" class="mr-1">{{ bike.ref }}</router-link>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="grey"
                        small
                        dense
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    {{ bike.booking.customer_name }} - {{ bike.type_label }}
                  </v-tooltip>
                  <ManifestJobNotesButton v-if="bike.booking.driver_notes.length > 0" class="mt-1" :job="bike" />
                </td>
                <td style="padding-bottom: 0px!important; padding-top: 0px!important; min-width: 190px">
                  <div style="display: flex; align-items: center; justify-content: space-between; white-space: nowrap;">
                    <span v-if="bike.booking_bike_manifest.estimated_cr_at" style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
                      <span>{{ $moment(bike.booking_bike_manifest.estimated_cr_at).format('h:mma') }}</span>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="grey"
                            small
                            dense
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span v-if="manifest.started_at">
                          This is an estimated time based on an actual start time of {{ $moment(manifest.started_at).format('h:mma') }}
                        </span>
                        <span v-else>
                          This is an estimated time based on a planned start time of {{ $moment(manifest.estimated_started_at).format('h:mma') }}
                        </span>
                      </v-tooltip>
                    </span>
                    <span v-if="bike.booking_bike_manifest.cr_at" class="pl-2" style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
                      <span class="font-weight-bold green--text">{{ $moment(bike.booking_bike_manifest.cr_at).format('h:mma') }}</span>
                      <v-icon color="green" small>mdi-check-circle</v-icon>
                    </span>
                  </div>
                </td>
                <td>
                  <v-chip small>
                    {{ bike.booking_bike_manifest.type === 'TYPE_RETURN' ? (bike.booking.return_time_window === 'ANY' ? 'Any Time' : getTimeWindowLabel(bike.booking.return_time_window)) : (bike.booking.collection_time_window === 'ANY' ? 'Any Time' : getTimeWindowLabel(bike.booking.collection_time_window)) }}
                  </v-chip>
                </td>
                <td style="padding-bottom: 0px!important; padding-top: 0px!important;">
                  <div style="display: flex; align-items: center; justify-content: space-between; white-space: nowrap;">
                    <v-select
                      v-model="bike.booking_bike_manifest.time_window"
                      :items="timeWindows.filter(timeWindow => ['FIRST', 'SECOND', 'THIRD', 'FOURTH', 'FITH'].includes(timeWindow.value))"
                      class="td_input"
                      small-chips
                      item-text="label"
                      item-value="value"
                      required
                      dense
                      @change="updateBike(bike)"
                    />
                    <v-tooltip
                      v-if="bike.booking_bike_manifest.type === 'TYPE_RETURN' ? (bike.booking.return_time_window !== 'ANY' && bike.booking_bike_manifest.time_window !== bike.booking.return_time_window) : (bike.booking.collection_time_window !== 'ANY' && bike.booking_bike_manifest.time_window !== bike.booking.collection_time_window)"
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="warning"
                          small
                          dense
                          class="ml-1"
                        >
                          mdi-alert-outline
                        </v-icon>
                      </template>
                      Requested time window does not match the assigned time window.
                    </v-tooltip>
                    <v-tooltip
                      v-else
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="green"
                          small
                          class="ml-1"
                        >
                          mdi-check-circle
                        </v-icon>
                      </template>
                      Requested time window and assigned time window match.
                    </v-tooltip>
                  </div>
                </td>
                <td>
                  <v-chip
                    small
                    outlined
                  >
                    {{ (bike.booking_bike_manifest.type === 'TYPE_RETURN') ? bike.return_status_label : bike.collection_status_label }}
                  </v-chip>
                </td>
                <td>
                  <v-chip
                    small
                    outlined
                  >
                    {{ bike.workshop_status_label }}
                  </v-chip>
                </td>
                <td>
                  <v-chip
                    :color="(bike.booking_bike_manifest.type === 'TYPE_RETURN') ? 'red' : 'green'"
                    dark
                    small
                  >
                    {{ bike.booking_bike_manifest.type_label }}
                  </v-chip>
                </td>
                <td style="display: flex; align-items: center; justify-content: space-between; white-space: nowrap;">
                  <span class="mr-1">{{ bike.booking_bike_manifest.type === 'TYPE_COLLECTION' ? bike.booking.collection_postcode :  bike.booking.return_postcode }}</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="grey"
                        small
                        dense
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ bike.booking_bike_manifest.type === 'TYPE_COLLECTION' ? bike.booking.collection_address :  bike.booking.return_address }}</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip v-if="bike.booking_bike_manifest.cr_notification_at" top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        color="success"
                        small
                        dense
                      >
                        mdi-check-circle-outline
                      </v-icon>
                    </template>
                    <span>{{ $momentDateTimeHuman(bike.booking_bike_manifest.cr_notification_at) }} by {{ bike.booking_bike_manifest.cr_notification_by ? bike.booking_bike_manifest.cr_notification_by.name : ' - ' }}</span>
                  </v-tooltip>
                  <span v-else>
                    -
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <SnackBar error multi-line ref="errorBar">
      {{ error.message }}
      <span
        v-if="error.errors"
        class="d-block"
      >
        <strong
          v-for="(error, key) in error.errors"
          :key="key"
          class="ml-2"
        >
          {{ error[0] }}
        </strong>
      </span>
    </SnackBar>

    <SnackBar success ref="successBar">
      Updated successfully!
    </SnackBar>

  </div>
</template>
<script>
import find from 'lodash.find'
import filter from 'lodash.filter'
import ManifestJobNotesButton from '@/components/manifests/ManifestJobNotesButton'
import SnackBar from '@/components/SnackBar'

export default {
  name: 'ManifestBikesTable',

  components: {
    ManifestJobNotesButton,
    SnackBar
  },

  props: {
    manifest: {
      type: Object,
      required: true
    },

    timeWindows: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },

  data () {
    return {
      bikes: [],
      error: {},
      bikesError: null,
      loading: false
    }
  },

  mounted () {
    this.getBikes()
  },

  methods: {
    reorder ({ newIndex, item }) {
      this.$api.persist('post', {
        path: `manifests/${this.manifest.id}/bikes/${item.dataset.bike}/reorder`,
        object: { order: newIndex+1 }
      })
        .then(() => {
          this.getBikes()
        })
    },

    getBikes () {
      this.loading = true
      this.bikesError = null

      this.$api.get({
        path: `manifests/${this.manifest.id}/bikes`,
        params: { with: ['booking.customer', 'booking.driverNotes.createdBy'], limit: 100 }
      })
        .then(({ data: bikes }) => {
          var returnBikes = filter(bikes, bike => {
            return bike.booking_bike_manifest.type === 'TYPE_RETURN'
          })
          var runningVanCount = returnBikes.length
          bikes.forEach((bike) => {
            if (bike.booking_bike_manifest.type === 'TYPE_RETURN') {
              runningVanCount--
            }
            if (bike.booking_bike_manifest.type === 'TYPE_COLLECTION') {
              runningVanCount++
            }
            this.$set(bike, '_running_van_count', runningVanCount)          
          })
          this.bikes = bikes
        })
        .catch(error => {
          this.bikesError = error
        })
        .finally(() => { this.loading = false })
    },

    getTimeWindowLabel (value) {
      return find(this.timeWindows, { value })?.label
    },

    updateBike (bike) {
      this.loading = true
      this.error = {}
      this.$api.persist('put', {
        path: `bike-manifests/${bike.booking_bike_manifest.id}`,
        object: bike.booking_bike_manifest
      })
        .then(() => {
          this.$refs.successBar.open()
        })
        .catch(error => {
          this.$refs.errorBar.open()
          this.error = error
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
